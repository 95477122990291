<template>
  <div v-if="productType.name === 'shoe'" class="content-wrapper">
    <h3>Coming Soon!</h3>
  </div>
  <div v-else class="content-wrapper">
    <div class="text-wrapper">
      <div class="required">
        <h3>{{ 'publicPage.requiredMeasurements' | t }}</h3>
        <div
          v-for="measurement in getMeasurements(productType.i18nKey, 'measurementsRequired')"
          :key="`${productType.i18nKey}-${measurement}`"
          class="measurements"
        >
          <p class="measurement-number">
            {{ `itemGuide.${productType.i18nKey}.measurementsRequired.${measurement}.number` | t }}
          </p>
          <p class="measurement-content">
            <span class="required-name">{{
              `itemGuide.${productType.i18nKey}.measurementsRequired.${measurement}.title` | t
            }}</span>
            <span class="text">{{
              `itemGuide.${productType.i18nKey}.measurementsRequired.${measurement}.text` | t
            }}</span>
          </p>
        </div>
      </div>
      <div class="optional">
        <h3>{{ 'publicPage.optionalMeasurements' | t }}</h3>
        <div
          v-for="measurement in getMeasurements(productType.i18nKey, 'measurementsOptionals')"
          :key="`${productType.i18nKey}: ${measurement}`"
          class="measurements"
        >
          <p class="measurement-number">
            {{ `itemGuide.${productType.i18nKey}.measurementsOptionals.${measurement}.number` | t }}
          </p>
          <p class="measurement-content">
            <span class="optional-name">{{
              `itemGuide.${productType.i18nKey}.measurementsOptionals.${measurement}.title` | t
            }}</span>
            <span class="text">{{
              `itemGuide.${productType.i18nKey}.measurementsOptionals.${measurement}.text` | t
            }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="product-image-wrapper">
      <img :alt="productType.imageName" :src="`${assetsUrl}/${productType.imageName}.jpg`">
    </div>
  </div>
</template>

<script>
import { ASSETS_BASE_URL } from '~/lib/urls'

export default {
  name: 'ProductType',
  props: {
    productType: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      assetsUrl: ASSETS_BASE_URL
    }
  },
  methods: {
    getMeasurements (itemName, measurementType = 'measurementsRequired') {
      return Object.keys(this.$t(`itemGuide.${itemName}.${measurementType}`))
    }
  }
}
</script>

<style lang="less" scoped>
@import '~/assets/styles/import.less';

.content-wrapper {
  display: flex;
  justify-content: space-between;

  .text-wrapper {
    h3 {
      margin: 10px 0;
      color: @black;
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
    }

    .measurements {
      display: flex;
      margin: 20px 0;
      font-weight: 300;
      font-size: 14px;
    }

    .measurement-number,
    .required-name {
      color: @blue;
      font-weight: bold;
    }

    .required-name,
    .optional-name {
      margin-right: 5px;
      font-weight: bold;
    }

    .measurement-number {
      width: 8%;
    }

    .measurement-content {
      width: 85%;
    }
  }

  .product-image-wrapper {
    width: 40%;
    border: 1px solid #cdcdcd;
    background-color: @white;

    img {
      width: 100%;
      padding: 10px 0;
    }
  }
}
</style>
