<template>
  <div
    v-if="i18nLoaded"
    id="main"
  >
    <Header @print="print" />
    <ContentBox
      class="product-select"
      :title="$t('publicPage.selectorHeader')"
    >
      <ProductList />
    </ContentBox>
    <ContentBox :title="$t('publicPage.generalGuideHeader')">
      <GuideLines />
    </ContentBox>
    <ContentBox
      v-for="productType in productTypes"
      v-show="isActiveProductType(productType.name)"
      :key="productType.name"
      :title="$t('publicPage.guideHeader', { itemName: $t(`itemGuide.${productType.i18nKey}.itemName`) })"
    >
      <ProductType :productType="productType" />
    </ContentBox>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Header from '~/components/header/Index'
import ContentBox from '~/components/contentBox/Index'
import ProductList from '~/components/productList/Index'
import GuideLines from '~/components/guideLines/Index'
import ProductType from '~/components/productType/Index'
import PRODUCT_TYPES from '~/lib/productTypes'

export default {
  name: 'App',
  components: {
    Header,
    ContentBox,
    ProductList,
    GuideLines,
    ProductType
  },
  data () {
    return {
      productTypes: PRODUCT_TYPES
    }
  },
  computed: {
    ...mapGetters([
      'activeProductType',
      'i18nLoaded'
    ])
  },
  methods: {
    isActiveProductType (productTypeName) {
      return this.activeProductType && this.activeProductType.name === productTypeName
    },
    print () {
      this.$htmlToPaper('main')
    }
  }

}
</script>

<style lang='less' scoped>
@import '~/assets/styles/import.less';

#main {
  width: 80%;
  max-width: 1200px;
  margin: 30px auto 0;
  background: @white;
}
</style>
