import Vue from 'vue'
import Vuex from 'vuex'
import 'es6-promise/auto' // https://vuex.vuejs.org/installation.html#promise
import i18nFetch from '~/api/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeProductType: null,
    i18nLoaded: false,
    i18nData: {}
  },
  getters: {
    activeProductType: state => state.activeProductType,
    i18nLoaded: state => state.i18nLoaded,
    i18nData: state => state.i18nData
  },
  actions: {
    setActiveProductType ({ commit }, productType) {
      commit('setActiveProductType', productType)
    },
    async fetchI18nData ({ commit }, locale = 'en') {
      let i18n = await i18nFetch(locale)
      commit('setI18nData', i18n)
    }
  },
  mutations: {
    setActiveProductType (state, payload) {
      state.activeProductType = payload
    },
    setI18nData (state, payload) {
      state.i18nData = payload
      state.i18nLoaded = true
    }
  }
})
