const PRODUCT_TYPES = [
  {
    name: 'dress',
    title: 'Dress',
    imageName: 'dress',
    i18nKey: 'dress'
  },
  {
    name: 'straplessdress',
    title: 'Strapless Dress',
    imageName: 'straplessDress',
    i18nKey: 'dressStrapless'
  },
  {
    name: 'sleevedress',
    title: 'Sleeved Dress',
    imageName: 'dressSleeve',
    i18nKey: 'dressSleeve'
  },
  {
    name: 'tunic',
    title: 'Tunic',
    imageName: 'tunic',
    i18nKey: 'dressALine'
  },
  {
    name: 'sleevetunic',
    title: 'Sleeved Tunic',
    imageName: 'tunicSleeve',
    i18nKey: 'tunicSleeve'
  },
  {
    name: 'top',
    title: 'Top',
    imageName: 'top',
    i18nKey: 'top'
  },
  {
    name: 'tshirt',
    title: 'T-Shirt',
    imageName: 'tShirt',
    i18nKey: 'tShirt'
  },
  {
    name: 'shirt',
    title: 'Shirt',
    imageName: 'shirt',
    i18nKey: 'shirt'
  },
  {
    name: 'sweater',
    title: 'Sweater',
    imageName: 'sweater',
    i18nKey: 'sweater'
  },
  {
    name: 'jacket',
    title: 'Jacket',
    imageName: 'jacket',
    i18nKey: 'jacket'
  },
  {
    name: 'coat',
    title: 'Coat',
    imageName: 'coat',
    i18nKey: 'coat'
  },
  {
    name: 'pants',
    title: 'Pants',
    imageName: 'pants',
    i18nKey: 'pants'
  },
  {
    name: 'shorts',
    title: 'Shorts',
    imageName: 'shorts',
    i18nKey: 'shorts'
  },
  {
    name: 'skirt',
    title: 'Skirt',
    imageName: 'skirt',
    i18nKey: 'skirt'
  },
  {
    name: 'shoe',
    title: 'Shoe',
    imageName: 'shoe',
    i18nKey: 'shoe'
  },
  {
    name: 'bag',
    title: 'Bag',
    imageName: 'bag',
    i18nKey: 'bag'
  },
  {
    name: 'clutch',
    title: 'Clutch',
    imageName: 'clutch',
    i18nKey: 'clutch'
  },
  {
    name: 'wallet',
    title: 'Wallet',
    imageName: 'wallet',
    i18nKey: 'wallet'
  }
]

export default PRODUCT_TYPES
