<template>
  <ul class="guidelines">
    <li>
      {{ 'publicPage.generalGuideItems.item1' | t }}
    </li>
    <li>
      {{ 'publicPage.generalGuideItems.item2' | t }}
    </li>
    <li>
      {{ 'publicPage.generalGuideItems.item3' | t }}
    </li>
    <li>
      {{ 'publicPage.generalGuideItems.item4' | t }}
    </li>
    <li>
      {{ 'publicPage.generalGuideItems.item5' | t }}
    </li>
    <li>
      {{ 'publicPage.generalGuideItems.item6' | t }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'GuideLines'
}
</script>

<style lang="less" scoped>
@import '~/assets/styles/import.less';

.guidelines {
  margin-left: 20px;
  list-style: disc;

  li {
    padding: 5px;
    color: @lightBlack;
    font-size: 14px;
  }
}
</style>
