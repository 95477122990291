<template>
  <span class="tooltip">{{ title }}</span>
</template>

<script>
export default {
  name: 'ToolTip',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
@import '~/assets/styles/import.less';

.tooltip {
  position: absolute;
  top: -50%;
  left: -7px;
  z-index: 1;
  width: 72px;
  padding: 5px 2px;
  border-radius: 6px;
  color: @white;
  font-size: 10px;
  text-align: center;
  background: @black;
  visibility: hidden;

  &::after {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-color: @black transparent transparent transparent;
    border-style: solid;
    border-width: 5px;
    content: " ";
  }
}
</style>
