<template>
  <div class="content-box-wrapper">
    <BoxTitle :title="title" />
    <BoxContent>
      <slot />
    </BoxContent>
  </div>
</template>

<script>
import BoxTitle from './BoxTitle'
import BoxContent from './BoxContent'

export default {
  name: 'ContentBox',
  components: {
    BoxTitle,
    BoxContent
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
@import '~/assets/styles/import.less';

.content-box-wrapper {
  margin: 16px auto;
  border: @baseBorder;
}
</style>
