<template>
  <div class="title">
    <span class="icon"><i class="icon-glyphicon icon-list" /></span>
    <h2>{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: 'BoxTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  }

}
</script>

<style lang="less" scoped>
@import '~/assets/styles/import.less';

.title {
  border-bottom: @baseBorder;
  background-color: @mediumGray;
  background-image: linear-gradient(to top, #fdfdfd 0, #eaeaea 100%);

  h2,
  .icon {
    display: inline-block;
    padding: 10px;
    color: #666;
  }

  .icon {
    border-right: @baseBorder;
  }
}
</style>
