<template>
  <li
    class="product-type"
    :class="{ active }"
    @click="setNewProductType(productType)"
  >
    <ToolTip :title="$t(`itemGuide.${productType.i18nKey}.itemName`)" />
    <span>
      <i
        class="icon-product-type"
        :class="`icon-${productType.name}`"
      />
    </span>
  </li>
</template>

<script>
import ToolTip from './ToolTip'

export default {
  name: 'ProductListElement',
  components: {
    ToolTip
  },
  props: {
    productType: {
      type: Object,
      default: function () {
        return { }
      }
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setNewProductType (productType) {
      this.$emit('setNewProductType', productType)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~/assets/styles/import.less';

.product-type {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 5px;
  border: 1px solid #a0a1a3;
  cursor: pointer;

  &:hover {
    .tooltip {
      visibility: visible;
    }
  }
}

.active {
  border-color: #2f96b4 #2f96b4 #1f6377;
  background: linear-gradient(to top, #5bc0de 0, #2f96b4 100%);
}
</style>
