export default () => {
  if (localStorage.getItem('measuringGuideLocale')) return localStorage.getItem('measuringGuideLocale')

  const url = window.location.href
  const endOfUrl = url.substr(url.lastIndexOf('.') + 1)
  const locales = [
    {
      code: 'en',
      domain: 'com'
    },
    {
      code: 'ja',
      domain: 'jp'
    },
    {
      code: 'ko',
      domain: 'kr'
    }
  ]

  locales.forEach(locale => {
    if (endOfUrl.startsWith(locale.domain)) {
      localStorage.setItem('measuringGuideLocale', locale.code)
    }
  })

  return localStorage.getItem('measuringGuideLocale') || 'en'
}
