import 'regenerator-runtime/runtime'
import Vue from 'vue'
import i18n from '~/filters/i18n'
import store from '~/store'
import App from './App'
import './htmlPrint'

Vue.use(i18n, { store })

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  store,
  render: h => h(App)
})
