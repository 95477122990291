<template>
  <ul class="product-type-list">
    <ProductListElement
      v-for="productType in productTypeList"
      :key="productType.name"
      :productType="productType"
      :active="isActive(productType.name)"
      @setNewProductType="setNewProductType"
    />
  </ul>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PRODUCT_LIST from '~/lib/productTypes'
import ProductListElement from './ProductListElement'

export default {
  name: 'ProductList',
  components: {
    ProductListElement
  },
  data () {
    return {
      productTypeList: PRODUCT_LIST
    }
  },
  computed: {
    ...mapGetters([
      'activeProductType'
    ])
  },
  methods: {
    ...mapActions([
      'setActiveProductType'
    ]),
    setNewProductType (productType) {
      this.setActiveProductType(productType)
    },
    isActive (productTypeName) {
      return this.activeProductType && this.activeProductType.name === productTypeName
    }
  }
}
</script>

<style lang="less" scoped>
.product-type-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
</style>
