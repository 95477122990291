<template>
  <div class="content">
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
@import '~/assets/styles/import.less';

.content {
  padding: 12px 15px;
  background-color: @lightGray;
}
</style>
