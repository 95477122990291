<template>
  <div class="header">
    <div>
      <h1>{{ 'publicPage.header' | t }}</h1>
      <LanguageSelector />
    </div>
    <button
      class="print-btn"
      @click="$emit('print')"
    >
      <span class="icon"><i class="icon-glyphicon icon-print" /></span>
      <span>{{ 'publicPage.print' | t }}</span>
    </button>
  </div>
</template>

<script>
import LanguageSelector from './LanguageSelector'

export default {
  name: 'Header',
  components: {
    LanguageSelector
  }
}
</script>
<style lang="less" scoped>
@import '~/assets/styles/import.less';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    display: inline-block;
    margin-right: 15px;
    font-size: 26px;
    vertical-align: middle;
  }

  .print-btn {
    padding: 11px 19px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 17.5px;
    line-height: 20px;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, @white, #e6e6e6);
    cursor: pointer;

    .icon {
      vertical-align: middle;

      .icon-print {
        margin-top: 2px;
      }
    }
  }
}

</style>
