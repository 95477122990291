import get from 'lodash/get'
import getLanguage from '~/lib/getLanguage'

export default {
  install (Vue, { store }) {
    const language = getLanguage()

    store.dispatch('fetchI18nData', language)

    /*
    * Substitutions of values in a i18n string
    * @param {String} text i18n raw string or key
    * @param {Object} substitutions Substitution object where the variable names are keys
    * @returns {String} Substituted string ready to be used in a template
    */

    const substitute = (text, substitutions) => {
      if (typeof text === 'object') {
        return text
      }
      if (text.indexOf('%{') < 0) {
        return text
      }

      let sText = text

      for (const variableName in substitutions) {
        sText = sText.replace(new RegExp(`%{${variableName}}`, 'g'), substitutions[variableName])
      }

      return sText
    }

    /*
    * i18n filter, accepts a map of substitutions.
    * Template follows interpolation delimiters `${}`.
    *
    * @param {String} key Dot separated string key for i18n property
    * @param {Object} substitutions Substitution object where the variable names are keys
    * @returns {String} Substituted string ready to be used in a template
    */
    const t = (key, substitutions) => {
      let i18nKey = key

      if (substitutions) {
        i18nKey = substitute(i18nKey, substitutions)
      }

      const value = get(store.getters.i18nData, i18nKey, `INVALID ${i18nKey}`)

      return substitute(value, substitutions)
    }

    // Syndicate t as a filter
    Vue.filter('t', t)
    Vue.prototype.$t = t
  }
}
